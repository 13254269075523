<!-- ./App.vue -->
<template>
  <div id="app">
    <!-- Include the header component -->
    <HeaderComponent 
      @show-card-list="showCardListComponent"
      @show-deck-builder="showDeckBuilderComponent"
      @show-print="showPrintComponent"
    />
    <!-- Main content -->
    <CardListComponent v-if="showCardList" />
    <DeckBuilderComponent v-if="showDeckBuilder" :refresh-key="refreshKey" />
    <PrintComponent v-if="showPrint" />
  </div>
</template>

<script>
import { provide, ref } from "vue";
import HeaderComponent from "./components/HeaderComponent.vue";
import CardListComponent from "./components/CardListComponent.vue";
import DeckBuilderComponent from "./components/DeckBuilderComponent.vue";
import PrintComponent from "./components/PrintComponent.vue";
import { getDeckBox, saveDeckBox, getDeckByName } from "./services/DeckService";
import { getCardData } from "./services/CardService";

export default {
  name: "App",
  components: {
    HeaderComponent,
    CardListComponent,
    DeckBuilderComponent,
    PrintComponent,
  },
  setup() {
    // Load deck box and card data
    const deckBox = ref(getDeckBox());
    const cardData = getCardData();
    const selectedDeck = ref(null);
    const refreshKey = ref(0);

    // Flags to control component visibility
    const showCardList = ref(true);
    const showDeckBuilder = ref(false);
    const showPrint = ref(false);

    // Update the selected deck in `deckBox`
    const updateSelectedDeck = () => {
      if (selectedDeck.value) {
        const deckIndex = deckBox.value.findIndex(
          (deck) => deck.name === selectedDeck.value.name
        );
        if (deckIndex !== -1) {
          deckBox.value[deckIndex] = selectedDeck.value;
          saveDeckBox(deckBox.value);
        }
      }
    };

    // Provide data to components
    provide("deckBox", deckBox);
    provide("selectedDeck", selectedDeck);
    provide("cardData", cardData);
    provide("saveDeckBox", () => {
      updateSelectedDeck();
      saveDeckBox(deckBox.value);
    });
    provide("getCardData", getCardData);
    provide("selectDeck", (deckName) => {
      const deck = getDeckByName(deckName);
      if (deck) selectedDeck.value = deck;
    });
    provide("deselectDeck", () => {
      selectedDeck.value = null;
    });

    // Methods to export data
    // function downloadJsonFile(filename, jsonData) {
    //   const blob = new Blob([JSON.stringify(jsonData, null, 2)], { type: "application/json" });
    //   const url = URL.createObjectURL(blob);

    //   const a = document.createElement('a');
    //   a.href = url;
    //   a.download = filename;
    //   document.body.appendChild(a); // Append anchor to body
    //   a.click();
    //   document.body.removeChild(a); // Remove anchor from body

    //   URL.revokeObjectURL(url);
    // }

    // function ExportCardNames() {
    //   const cardNames = cardData.map(card => card.name);
    //   downloadJsonFile('card_names.json', cardNames);
    // }

    // function ExportBasicData() {
    //   const basicData = cardData.map(card => ({
    //     name: card.name,
    //     elementDisplay: card.ElementDisplay,
    //     typeDisplay: card.TypeDisplay,
    //     subtypeDisplay: card.SubtypeDisplay,
    //     cost: card.cost,
    //     attack: card.attack,
    //     health: card.health,
    //     rulesTextExportable: card.exportableText,
    //   }));
    //   downloadJsonFile('card_basic_data.json', basicData);
    // }

    // Call the export methods during app setup
    // ExportCardNames();
    // ExportBasicData();

    // Methods to show/hide components
    const showCardListComponent = () => {
      showCardList.value = true;
      showDeckBuilder.value = false;
      showPrint.value = false;
    };

    const showDeckBuilderComponent = () => {
      showCardList.value = false;
      showDeckBuilder.value = true;
      showPrint.value = false;
      refreshKey.value++; // Increment refreshKey to trigger table re-render
    };

    const showPrintComponent = () => {
      showCardList.value = false;
      showDeckBuilder.value = false;
      showPrint.value = true;
    };

    return {
      showCardList,
      showDeckBuilder,
      showPrint,
      showCardListComponent,
      showDeckBuilderComponent,
      showPrintComponent,
      refreshKey,
    };
  },
};
</script>
