<!-- ./components/DeckBuilderComponent.vue -->
<template>
  <div class="deck-builder">
    <h2>Deck Builder</h2>

    <!-- New Deck Section -->
    <div class="new-deck mb-4">
      <div class="input-group">
        <input v-model="newDeckName" type="text" class="form-control" placeholder="Enter deck name" />
        <button @click="createNewDeck" class="btn btn-primary">New Deck</button>
      </div>
    </div>

    <!-- Upload Deck -->
    <div class="upload-deck mt-4">
      <h3>Upload Deck</h3>
      <div class="input-group">
        <input type="file" @change="uploadDeck" class="form-control" accept=".json" />
      </div>
    </div>

    <!-- Deck List -->
    <div class="deck-list col-12 col-md-10 col-lg-8 col-xl-6">
      <h3>Your Decks</h3>
      <ul class="list-group">
        <li v-for="(deck, index) in deckBox" :key="index"
          class="list-group-item d-flex justify-content-between align-items-center clickable"
          @click="handleDeckClick(index, $event)">
          <div class="deck-info">
            <span :class="['deck-name', { selected: selectedDeck && selectedDeck.name === deck.name }]">
              {{ deck.name }}
            </span>

            <div class="input-group" v-if="editingDeckIndex === index">
              <input v-model="editingDeckName" type="text" class="form-control" />
              <button @click.stop="saveDeckName(index)" class="btn btn-success">Save</button>
            </div>
          </div>

          <div class="deck-actions">
            <button v-if="editingDeckIndex !== index" @click.stop="startEditingDeck(index, deck.name)"
              class="btn btn-warning btn-sm">
              Rename
            </button>
            <button @click.stop="downloadDeck(deck)" class="btn btn-info btn-sm mx-1">Download</button>
            <button @click.stop="copyForTTS(deck)" class="btn btn-secondary btn-sm mx-1">Copy for TTS</button>
            <button @click.stop="deleteDeck(index)" class="btn btn-danger btn-sm mx-1">Delete</button>
          </div>
        </li>
      </ul>
    </div>

    <!-- Selected Deck Card List -->
    <div v-if="selectedDeck" class="deck-card-list mt-4">
      <h3>Cards in Deck ({{ totalCardCount }})</h3>
      <table class="table table-bordered custom-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Element</th>
            <th>Type</th>
            <th>Subtype</th>
            <th>Cost</th>
            <th>Attack</th>
            <th>Health</th>
            <th>Rules Text</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(card, index) in displayedDeckCards" :key="index">
            <td>{{ card.name }}</td>
            <td :style="getElementColumnStyle(card)" class="text-outline">
              {{ card.ElementDisplay }}
            </td>
            <td>{{ card.TypeDisplay }}</td>
            <td>{{ card.SubtypeDisplay }}</td>
            <td>{{ card.CostDisplay }}</td>
            <td>{{ card.attack }}</td>
            <td>{{ card.health }}</td>
            <td v-html="card.RulesTextDisplay.replace(/\n/g, '<br>')"></td>
            <td class="d-flex align-items-center">
              <button class="btn btn-primary btn-sm" @click="decrementCardCount(card)" :disabled="card.count <= 0">
                -
              </button>
              <span class="mx-2">{{ card.count }}</span>
              <button class="btn btn-primary btn-sm" @click="incrementCardCount(card)"
                :disabled="card.count >= 3 && !card.NoCardLimitInd">
                +
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { inject, ref, onMounted, computed } from "vue";

export default {
  name: "DeckBuilderComponent",
  setup() {
    const deckBox = inject("deckBox");
    const selectedDeck = inject("selectedDeck");
    const selectDeck = inject("selectDeck");
    const deselectDeck = inject("deselectDeck");
    const getCardData = inject("getCardData");
    const saveDeckBox = inject("saveDeckBox");

    const newDeckName = ref("");
    const editingDeckIndex = ref(null);
    const editingDeckName = ref("");
    const displayedDeckCards = ref([]);

    const refreshDeckCards = () => {
      if (!selectedDeck.value) {
        displayedDeckCards.value = [];
        return;
      }

      const deckCardDetails = getCardData(selectedDeck.value.cards);
      displayedDeckCards.value = deckCardDetails.map((card) => {
        const matchingCard = selectedDeck.value.cards.find((deckCard) => deckCard.name === card.name);
        return { ...card, count: matchingCard.count };
      });
    };

    const totalCardCount = computed(() => {
      if (!selectedDeck.value || !selectedDeck.value.cards) return 0;
      return selectedDeck.value.cards.reduce((sum, card) => sum + card.count, 0);
    });

    const downloadDeck = (deck) => {
      const dataStr = JSON.stringify(deck, null, 2);
      const blob = new Blob([dataStr], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${deck.name}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const copyForTTS = (deck) => {
      const deckCards = deck.cards || [];

      // Create an array of objects with name and count
      const cardNamesWithCounts = deckCards.map(c => ({
        name: c.name,
        count: c.count
      }));

      // Sort by card name (optional, depending on preference)
      cardNamesWithCounts.sort((a, b) => a.name.localeCompare(b.name));

      // Create a compact JSON-formatted string
      const deckJsonString = JSON.stringify(cardNamesWithCounts);

      navigator.clipboard.writeText(deckJsonString)
        .then(() => {
        })
        .catch(err => {
          console.error("Failed to copy deck to clipboard:", err);
        });
    };

    const deleteDeck = (index) => {
      const deckName = deckBox.value[index].name;

      // Remove the deck from the deckBox
      deckBox.value.splice(index, 1);

      // If the deleted deck is the currently selected one, deselect it
      if (selectedDeck.value && selectedDeck.value.name === deckName) {
        deselectDeck();
      }

      saveDeckBox(deckBox.value);
    };

    const uploadDeck = (event) => {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const uploadedDeck = JSON.parse(e.target.result);
          if (!uploadedDeck.name || !Array.isArray(uploadedDeck.cards)) {
            alert("Invalid deck format.");
            return;
          }

          if (deckBox.value.some((deck) => deck.name === uploadedDeck.name)) {
            alert("A deck with this name already exists.");
            return;
          }

          deckBox.value.push(uploadedDeck);
          saveDeckBox(deckBox.value);
        } catch (error) {
          alert("Failed to upload the deck.");
        }
      };
      reader.readAsText(file);
    };

    const incrementCardCount = (card) => {
      if (!selectedDeck.value) return;

      const existingCard = selectedDeck.value.cards.find((deckCard) => deckCard.name === card.name);
      if (existingCard) {
        existingCard.count += 1;
      } else {
        selectedDeck.value.cards.push({ name: card.name, count: 1 });
      }

      refreshDeckCards();
      saveDeckBox(deckBox.value);
    };

    const decrementCardCount = (card) => {
      if (!selectedDeck.value) return;

      const existingCard = selectedDeck.value.cards.find((deckCard) => deckCard.name === card.name);
      if (existingCard) {
        existingCard.count -= 1;

        if (existingCard.count <= 0) {
          const cardIndex = selectedDeck.value.cards.indexOf(existingCard);
          if (cardIndex > -1) {
            selectedDeck.value.cards.splice(cardIndex, 1);
          }
        }
      }

      refreshDeckCards();
      saveDeckBox(deckBox.value);
    };

    const createNewDeck = () => {
      const name = newDeckName.value.trim();
      if (!name) {
        alert("Please enter a deck name.");
        return;
      }

      if (deckBox.value.some((deck) => deck.name === name)) {
        alert("A deck with this name already exists.");
        return;
      }

      const newDeck = { name, creationDate: new Date().toISOString(), cards: [] };
      deckBox.value.push(newDeck);
      selectDeck(name);
      saveDeckBox(deckBox.value);
      newDeckName.value = "";
    };

    const handleDeckClick = (index, event) => {
      if (
        event.target.tagName === "INPUT" ||
        event.target.tagName === "BUTTON" ||
        editingDeckIndex.value === index
      ) {
        return;
      }

      if (selectedDeck.value && selectedDeck.value.name === deckBox.value[index].name) {
        deselectDeck();
        displayedDeckCards.value = [];
      } else {
        selectDeck(deckBox.value[index].name);
        refreshDeckCards();
      }
    };

    const startEditingDeck = (index, currentName) => {
      editingDeckIndex.value = index; // Set the editing index
      editingDeckName.value = currentName; // Initialize the input with the current deck name
    };

    const saveDeckName = (index) => {
      const newName = editingDeckName.value.trim();

      if (!newName) {
        alert("Deck name cannot be empty.");
        return;
      }

      if (deckBox.value.some((deck, idx) => idx !== index && deck.name === newName)) {
        alert("A deck with this name already exists.");
        return;
      }

      // Update the deck name
      deckBox.value[index].name = newName;

      // Save the updated deck box
      saveDeckBox(deckBox.value);

      // Clear editing state
      editingDeckIndex.value = null;
      editingDeckName.value = "";

      deselectDeck();
    };

    const elementColors = {
      Neutral: "#cccccc",
      Abyssal: "#45818e",
      Arcane: "#bd94a8",
      Force: "#dd7e6b",
      Light: "#f1c232",
      Noxious: "#8e7cc3",
      Storm: "#b6d7a8",
      Terra: "#f9cb9c",
      Thermal: "#6d9eeb",
    };

    const getElementColumnStyle = (card) => {
      const elements = card.element || [];
      if (elements.length === 1) {
        return { backgroundColor: elementColors[elements[0]] };
      } else if (elements.length > 1) {
        const gradientColors = elements
          .map((element) => elementColors[element] || "#ffffff")
          .join(", ");
        return {
          backgroundImage: `linear-gradient(to right, ${gradientColors})`,
        };
      }
      return {};
    };

    onMounted(() => {
      refreshDeckCards();
    });

    return {
      deckBox,
      selectedDeck,
      newDeckName,
      editingDeckIndex,
      editingDeckName,
      getElementColumnStyle,
      createNewDeck,
      elementColors,
      handleDeckClick,
      refreshDeckCards,
      displayedDeckCards,
      incrementCardCount,
      decrementCardCount,
      downloadDeck,
      copyForTTS,
      uploadDeck,
      deleteDeck,
      startEditingDeck,
      saveDeckName,
      totalCardCount,
    };
  },
};
</script>
